module services {   
    'use strict';
    export module sales {
        export class SalesOrderSKUService implements interfaces.sales.ISalesOrderSKUService {
            static $inject = ['$resource', 'ENV'];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {

            }

            getSalesOrderSKUListBySOId(): ng.resource.IResourceClass<interfaces.sales.ISalesOrderDetailSKUDisplay> {
                return this.$resource<interfaces.sales.ISalesOrderDetailSKUDisplay>(this.ENV.DSP_URL + "SalesOrderSKU/GetListBySalesOrderId", {
                    salId: '@salId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            params: {
                                salId: 0
                            }
                        }
                    });
            }

            getDetail(): ng.resource.IResourceClass<interfaces.sales.ISalesOrderSKU> {
                return this.$resource<interfaces.sales.ISalesOrderSKU>(this.ENV.DSP_URL + "SalesOrderSKU/GetDetail", {
                    sosId: '@sosId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            params: {
                                sosId: 0
                            },
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrderSKU/Save");
            }

            saveInline(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrderSKU/SaveInline");
            }

            delete(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrderSKU/Delete", {
                    salesOrderDetailId: '@salesOrderDetailId'
                });
            }

            deleteNationalMeasureCode(pnmId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrderSKU/DeleteNationalMeasureCode", {
                    pnmId: pnmId
                });
            }


            getSKUDefaults(): ng.resource.IResourceClass<interfaces.master.ISKU> {

                return this.$resource<interfaces.master.ISKU>(this.ENV.DSP_URL + "SalesOrderSKU/GetSKUDefaults", {
                    skuId: "@skuId",
                    sosId: '@sosId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false
                        }
                    });
            }
        }
    }
    angular.module("app").service("salesOrderSKUService", services.sales.SalesOrderSKUService);
}